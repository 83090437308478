import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Les atouts de box-sizing: border-box",
  "description": "Présentation d’une propriété CSS maintenant devenue incontournable.",
  "date": "2012-08-15T00:00:00.000Z",
  "path": "/articles/les-atouts-de-box-sizing-border-box/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Par défaut, la largeur totale d’une boîte sera égale à la largeur qui lui a été attribuée + son padding (`}<code parentName="p" {...{
        "className": "text"
      }}>{`box-sizing: content-box`}</code>{`). Par exemple, si l’on veut avoir une boîte d’une largeur de 100px, avec un padding de 10px, il faudra spécifier dans le css une `}<code parentName="p" {...{
        "className": "text"
      }}>{`width:80px`}</code>{` pour celle-ci (80px + 10 px + 10px). Pas vraiment pratique, surtout lorsqu’on veut utiliser des boîtes de taille fluide avec des paddings fixes…`}</p>
    <p>{`Heureusement, La valeur `}<code parentName="p" {...{
        "className": "text"
      }}>{`border-box`}</code>{` de la propriété CSS `}<code parentName="p" {...{
        "className": "text"
      }}>{`box-sizing`}</code>{`, apparemment peu connue et peu utilisée jusqu’à assez récemment, présente des atouts considérables dans la gestion des `}<code parentName="p" {...{
        "className": "text"
      }}>{`width`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`padding`}</code>{`. Le `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/fr/docs/CSS/-moz-box-sizing"
      }}>{`journal des développeurs`}</a>{` de Mozilla en donne la définition suivante :`}</p>
    <blockquote>
      <p parentName="blockquote">{`Les propriétés de largeur et de hauteur incluent la taille du padding et la bordure, en excluant la marge. C’est le modèle de boîte utilisé par Internet Explorer lorsque le document n’est pas en mode de compatibilité des standards.`}</p>
    </blockquote>
    <p>{`Ainsi, si l’on reprend le premier exemple, une boîte à qui on a attribué cette propriété fera toujours une largeur de 100px, quelque soit le padding et la bordure qu’on lui attribue. Même chose pour une boîte de largeur fluide, par exemple de 100% : si on lui attribue un padding de 5px, elle ne débordera pas de sa taille d’origine.`}</p>
    <p>{`Je me suis mis à récemment utiliser `}<code parentName="p" {...{
        "className": "text"
      }}>{`box-sizing: border-box`}</code>{` pour un projet sur lequel je travaille. C’est peu dire qu’elle a complètement changé mon mode de fonctionnement, et je vous invite aussi à tester ça au plus vite.`}</p>
    <h2>{`Utilisation`}</h2>
    <p><a parentName="p" {...{
        "href": "http://paulirish.com"
      }}>{`Paul Irish`}</a>{`, dans son `}<a parentName="p" {...{
        "href": "http://paulirish.com/2012/box-sizing-border-box-ftw/"
      }}>{`article`}</a>{` vantant les mérites de `}<code parentName="p" {...{
        "className": "text"
      }}>{`box-sizing: border-box`}</code>{`, préconise d’utiliser le CSS suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-moz-box-sizing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-box-sizing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-sizing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`De cette manière, tous les éléments seront concernés par ce changement, et vous n’aurez plus besoin de vous casser la tête pour calculer leurs tailles.`}</p>
    <h2>{`Support par les navigateurs`}</h2>
    <p>{`Les navigateurs récents, IE8 compris, supportent ce mode de fonctionnement. Pour IE 6/7, on pourra avoir recours au `}<a parentName="p" {...{
        "href": "https://github.com/Schepp/box-sizing-polyfill"
      }}>{`box-sizing polyfill`}</a>{` pour contourner le problème. Je l’ai personnellement testé avec succès sur IE7.`}</p>
    <p>{`Pour résumer, à mes yeux cette méthode est à utiliser sans aucun risque. Elle présente énormément d’atouts, et aucun bug à ma connaissance (il existait un bug avec jQuery mais celui-ci `}<a parentName="p" {...{
        "href": "http://bugs.jquery.com/ticket/11004"
      }}>{`semble avoir été corrigé`}</a>{` depuis). Je ne peux donc que vous recommander de passer à `}<code parentName="p" {...{
        "className": "text"
      }}>{`box-sizing: border-box`}</code>{` pour vos futurs projets :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      